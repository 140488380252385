:root {
  --green: #599854;
  --yellow: #c5b466;
  --lighterGray: #e2e3e4;
  --lightGray: #d1d4d9;
  --darkGray: #777d81;
  --darkGrayTransparent: rgba(119, 125, 129, 0.567);
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
}

.SettingsModal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  font-size: 22px;
  border: 18px solid var(--darkGrayTransparent);
  overflow-y: scroll;
}

.SettingsHeader {
  padding: 12px 0 2px;
  margin: 0;
  font-size: 22px;
  text-align: center;
  font-family: 'Alfa Slab One', Tahoma, sans-serif;
  font-weight: 300;
  flex: 1;
}

.SettingsInfo {
  font-family: Tahoma, sans-serif;
  margin: 12px 6px 24px;
  font-size: 14px;
  line-height: 170%;
}

.WordEntryInput {
  text-transform: uppercase;
  text-align: center;
  padding: 8px;
  border: 1px solid var(--lightGray);
  min-width: 180px;
}

.AllowedGuessesEntry {
  text-align: center;
  margin: 22px 0;
  display: flex;
  justify-content: center;
  gap: 18px;
}

.AllowedGuessesText {
  height: 42px;
  font-size: 32px;
  line-height: 42px;
}

.AllowedGuessesButton {
  display: inline-block;
  width: 42px;
  height: 42px;
}

.ShareLinkText {
  font-size: 16px;
  margin: 12px 6px;
  word-wrap: break-word;
  min-height: 54px;
}

.CopiedText {
  font-size: 16px;
  margin: 12px 6px;
  min-height: 44px;
}


.SettingsButton {
  background-color: var(--lightGray);
  color: black;
  height: 42px;
  font-size: 18px;
  padding: 0 22px;
  border: none;
}

.SettingsButton:disabled {
  background-color: var(--lighterGray);
  color: var(--darkGrayTransparent);
  height: 42px;
  font-size: 18px;
  padding: 0 22px;
  border: none;
}


.GreenButton {
  background-color: var(--green);
  color: black;
  height: 42px;
  font-size: 18px;
  padding: 0 22px;
  border: none;
}

.SettingsFooter {
  margin: 24px 0;
  display: flex;
  justify-content: center;
  column-gap: 12px;
}

.HeaderSpacer {
  padding-left: 10px;
  width: .65em;
}

.Header {
  display: flex;
  font-size: 32px;
  padding: 4px 6px 8px;
  font-family: 'Alfa Slab One', Tahoma, sans-serif;
  border-bottom: 1px solid var(--lightGray);
}

.HeaderSettings {
  padding-right: 10px;
  width: .65em;
}

.HeaderText {
  flex: 1;
}

.WordRows {
  flex: 1;
  overflow-y: scroll;
  padding: 12px 0;
}

.WordRow {
  display: flex;
  justify-content: center;
  width: 100vw;
  flex-wrap: wrap;
  column-gap: 18px;
  padding: 0 0 8px 0;
}

.Word {
  display: flex;
  justify-content: center;
}

.LetterSquare {
  margin: 2px;
  font-family: 'Secular One', Tahoma, sans-serif;
  font-size: 32px;
  width: 36px;
  height: 56px;
  line-height: 60px;
  border: 2px solid var(--lightGray);
}

.GreenSquare {
  border-color: var(--green);
  background-color: var(--green);
  color: white;
}

.YellowSquare {
  border-color: var(--yellow);
  background-color: var(--yellow);
  color: white;
}

.GreySquare {
  border-color: var(--darkGray);
  background-color: var(--darkGray);
  color: white;
}


.Keyboard {
  box-shadow: 0 -1px 2px var(--lighterGray);
  width: 100vw;
  padding: 2px 0 8px;
}

.KeyboardRow {
  display: flex;
  justify-content: center;
}


.Key {
  font-family: 'Secular One', Tahoma, sans-serif;
  background-color: var(--lightGray);
  margin: 4px 3px;
  padding: 2px;
  font-size: 18px;
  width: 1.6em;
  height: 54px;
  line-height: 54px;
  border-radius: 4px;
}

.GreyKey {
  color: white;
  background-color: var(--darkGray);
}

.YellowKey {
  color: white;
  background-color: var(--yellow);
}

.GreenKey {
  color: white;
  background-color: var(--green);
}

.EnterKey {
  font-size: 16px;
  width: 3.8em;
}

.BackKey {
  font-size: 16px;
  width: 3.2em;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
